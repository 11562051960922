import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default","HomeTabs"] */ "/vercel/path0/src/app/(home)/layout.client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/display/badge.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/display/card.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/event-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/input/button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/navigation-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TabsContent"] */ "/vercel/path0/src/components/navigation/tabs.tsx");
